import type { ImageField } from "@prismicio/client"
import { asImageSrc } from "@prismicio/client"
import { i18n } from "next-i18next"
import * as React from "react"
import { JsonLd, jsonLdScriptProps } from "react-schemaorg"
import type {
  AggregateRating,
  Article,
  BreadcrumbList,
  DayOfWeek,
  FAQPage,
  GeoCoordinates,
  LocalBusiness,
  OpeningHoursSpecification,
  Organization,
  Person,
  Product,
  Review,
  SelfStorage,
  WebSite,
} from "schema-dts"

import { DateTime } from "@bounce/date"
import type { Locale } from "@bounce/i18n"
import { DEFAULT_LANGUAGE, useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"

import { config } from "@/config"
import type { WithOpeningHoursFragment } from "@/libs/bounce/bounceSdk.types.gen"
import { useUrl } from "@/libs/router"
import type { Breadcrumb } from "@/types"
import type { FAQ } from "@/utils/boilerplate"
import type { Review as ReviewType } from "@/utils/reviews"

const DAYS_IN_A_WEEK = 7

const BOUNCE_ORGANIZATION: Organization = {
  "@type": "Organization",
  name: "Bounce",
  logo: `https://${config.domain}/static/favicon-512x512.png`,
  url: `https://${config.domain}/`,
  sameAs: [
    "https://www.instagram.com/bouncemystuff",
    "https://twitter.com/bouncemystuff",
    "https://www.facebook.com/BounceYourStuff",
    "https://www.linkedin.com/company/bounceyourstuff",
    "https://www.tiktok.com/@bouncemystuff",
  ],
}

/*
  Transform prismic image into an array of multiple image ration for google markups
  If the image is a string (url) return it as is
  ratios: 16x9, 4x3, and 1x1
  @see https://developers.google.com/search/docs/appearance/structured-data/article#:~:text=For%20best%20results%2C%20we%20recommend%20providing%20multiple%20high%2Dresolution%20images%20(minimum%20of%2050K%20pixels%20when%20multiplying%20width%20and%20height)%20with%20the%20following%20aspect%20ratios%3A%2016x9%2C%204x3%2C%20and%201x1.
*/
const imageToMarkupRatios = (image: string | ImageField): string | string[] => {
  if (typeof image === "string") return [image]

  const imageRatios = [
    asImageSrc(image, { ar: "1:1", fit: "crop" }),
    asImageSrc(image, { ar: "1.33:1", fit: "crop" }),
    asImageSrc(image, { ar: "1.7:1", fit: "crop" }),
  ]

  return imageRatios.filter(Boolean) as string[]
}

const getDayOfWeek = (day: number): DayOfWeek =>
  DateTime.fromObject({ weekday: day + 1 }).toLocaleString({ weekday: "long" }, { locale: "en-US" }) as DayOfWeek

const removeTruncatedUnicodeCharacters = (text: string) => text.replace(/[\uD800-\uDFFF]/g, "")

type FAQStructuredDataProps = {
  faqs: FAQ[]
}
/**
 * FAQ (FAQPage, Question, Answer) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/faqpage
 */
export const FAQStructuredData = ({ faqs }: FAQStructuredDataProps) => (
  <JsonLd<FAQPage>
    item={{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqs.map(qa => {
        return {
          "@type": "Question",
          name: qa.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: qa.structuredAnswer ?? (qa.answer as string),
          },
        }
      }),
    }}
  />
)

/**
 * Only on homepage
 * @see https://developers.google.com/search/docs/data-types/sitelinks-searchbox
 */
export const SearchboxStructuredData = () => (
  <JsonLd<WebSite>
    item={{
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Bounce Luggage Storage",
      alternateName: ["Bounce"],
      url: `https://${config.domain}/`,
      potentialAction: {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `https://${config.domain}/s?query={search_term_string}`,
        },
        // @ts-expect-error type issue form react-schemaorg 👉 https://github.com/google/react-schemaorg/issues/6
        "query-input": "required name=search_term_string",
      },
    }}
  />
)

/**
 * Logo (Organization) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/logo
 */
export const LogoStructuredData = () => (
  <JsonLd<Organization>
    item={{
      "@context": "https://schema.org",
      ...BOUNCE_ORGANIZATION,
    }}
  />
)

const getOpeningHoursSpecificationItem = ({
  opens,
  closes,
  dayOfWeek,
}: Pick<OpeningHoursSpecification, "opens" | "closes"> & {
  dayOfWeek: number | number[]
}): OpeningHoursSpecification => ({
  "@type": "OpeningHoursSpecification",
  dayOfWeek: Array.isArray(dayOfWeek) ? dayOfWeek.map(getDayOfWeek) : getDayOfWeek(dayOfWeek),
  opens,
  closes,
})

const getOpeningHoursSpecification = (openingHours?: WithOpeningHoursFragment["openingHours"]) => {
  if (!openingHours) {
    return undefined
  }

  const specifications: OpeningHoursSpecification[] = []
  const uniqueDaysOfWeek = new Set(openingHours.map(({ dayOfWeek }) => dayOfWeek))
  const daysClosed = Array.from(Array(DAYS_IN_A_WEEK), (_, x) => x).filter(day => !uniqueDaysOfWeek.has(day))

  if (daysClosed.length) {
    // To show a business is closed all day, set both opens and closes properties to "00:00".
    // https://developers.google.com/search/docs/appearance/structured-data/local-business
    specifications.push(
      getOpeningHoursSpecificationItem({ opens: "00:00:00", closes: "00:00:00", dayOfWeek: daysClosed }),
    )
  }

  openingHours.forEach(({ openTime: opens, closeTime, dayOfWeek }) => {
    // To show a business as open 24 hours a day, set the open property to "00:00" and the closes property to "23:59".
    const isOpen24Hours = opens === "00:00:00" && closeTime === "00:00:00"
    const closes = isOpen24Hours ? "23:59:00" : closeTime
    const specificationForSameHours = specifications.find(spec => spec.opens === opens && spec.closes === closes)

    if (specificationForSameHours) {
      const { dayOfWeek: sameHoursDayOfWeek } = specificationForSameHours
      specificationForSameHours.dayOfWeek = [
        ...(Array.isArray(sameHoursDayOfWeek) ? sameHoursDayOfWeek : [sameHoursDayOfWeek]),
        getDayOfWeek(dayOfWeek),
      ]

      return
    }

    specifications.push(getOpeningHoursSpecificationItem({ opens, closes, dayOfWeek }))
  })

  return specifications
}

type GetAggregateRatingSpecificationOptions = {
  value: AggregateRating["ratingValue"]
  count: number
}

export const getAggregateRatingSpecification = ({
  value,
  count,
}: GetAggregateRatingSpecificationOptions): AggregateRating => ({
  "@type": "AggregateRating",
  reviewCount: count,
  ratingCount: count,
  ratingValue: value,
})

type GetReviewsSpecificationOptions = {
  reviews?: Pick<ReviewType, "rating" | "user" | "feedback" | "translatedFeedback" | "completedAt">[]
  language?: Locale["language"]
}

export const getReviewsSpecification = ({
  reviews,
  language = i18n ? i18n.language : DEFAULT_LANGUAGE,
}: GetReviewsSpecificationOptions): Review[] | undefined => {
  if (!reviews) {
    return undefined
  }

  return reviews.map(({ rating, user, feedback, translatedFeedback, completedAt }) => {
    const reviewBodyToUse = translatedFeedback ?? feedback

    return {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        bestRating: 5,
        ratingValue: rating,
        worstRating: 1,
      },
      author: {
        "@type": "Person",
        name: removeTruncatedUnicodeCharacters(user.fullName + "\ud83d"),
      },
      reviewBody: reviewBodyToUse ? removeTruncatedUnicodeCharacters(reviewBodyToUse) : "",
      publisher: BOUNCE_ORGANIZATION,
      datePublished: DateTime.fromISO(completedAt).toISODate(),
      inLanguage: language,
    }
  })
}

export type SelfStorageStructuredDataProps = {
  addressCountry?: string
  addressLocality?: string
  addressRegion?: string | null
  alternateName: string
  description: string
  image: string | ImageField
  latitude?: number
  longitude?: number
  name: string
  openingHours?: WithOpeningHoursFragment["openingHours"]
  postalCode?: string
  price?: string
  ratingValue: number
  reviewCount: number
  reviews?: ReviewType[]
  streetAddress?: string
  telephone?: string | null
  url: string
}
/**
 * Local business (LocalBusiness) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/local-business
 */
export const SelfStorageStructuredData = ({
  addressCountry,
  addressLocality,
  addressRegion,
  alternateName,
  description,
  image = `https://${config.domain}/static/hero.png`,
  latitude,
  longitude,
  name,
  openingHours,
  postalCode,
  price,
  ratingValue,
  reviewCount,
  reviews,
  streetAddress,
  telephone,
  url,
}: SelfStorageStructuredDataProps) => {
  const { t } = useTranslation()

  return (
    <JsonLd<SelfStorage>
      item={{
        "@context": "https://schema.org",
        "@type": "SelfStorage",
        "@id": url,
        name,
        alternateName,
        image: imageToMarkupRatios(image),
        logo: `https://${config.domain}/static/favicon-512x512.png`,
        url,
        description,
        telephone: telephone ?? undefined,
        email: "team@usebounce.com",
        parentOrganization: BOUNCE_ORGANIZATION,
        address: {
          "@type": "PostalAddress",
          addressCountry,
          addressLocality,
          addressRegion: addressRegion ?? undefined,
          postalCode,
          streetAddress,
        },
        geo: getGeoCoordinatesSpecification({ latitude, longitude }),
        priceRange: t("cmp.head.price", "From {{price}}/day", { price }),
        aggregateRating: getAggregateRatingSpecification({
          count: reviewCount,
          value: ratingValue,
        }),
        review: getReviewsSpecification({
          reviews,
        }),
        openingHoursSpecification: getOpeningHoursSpecification(openingHours),
      }}
    />
  )
}

export type ProductReviewStructuredDataProps = {
  name: string
  description: string
  image: string | ImageField
  ratingValue: number
  reviewCount: number
  reviews?: ReviewType[]
  url: string
  lowestPrice: string
  currency?: string
}

/**
 * Product review (Product) structured data for a product review page
 * @see https://developers.google.com/search/docs/appearance/structured-data/product-snippet#product-review-page-example
 */
export const ProductReviewStructuredData = ({
  name,
  description,
  image,
  ratingValue,
  reviewCount,
  reviews,
  lowestPrice,
  currency = "USD",
  url,
}: ProductReviewStructuredDataProps) => {
  const { i18n } = useTranslation()

  return (
    <JsonLd<Product>
      item={{
        "@context": "https://schema.org",
        "@type": "Product",
        name,
        description,
        image: imageToMarkupRatios(image),
        aggregateRating: getAggregateRatingSpecification({
          count: reviewCount,
          value: ratingValue,
        }),
        review: getReviewsSpecification({
          reviews,
          language: i18n.language,
        }),
        url,
        offers: {
          "@type": "Offer",
          availability: "https://schema.org/InStock",
          price: lowestPrice, // Must be a string with period as the decimal separator
          priceCurrency: currency,
          priceValidUntil: DateTime.now().plus({ years: 1 }).toISODate(),
          url,
        },
        brand: BOUNCE_ORGANIZATION,
      }}
    />
  )
}

export type LocalBusinessStructuredDataProps = {
  name: string
  description: string
  image: string | ImageField
  addressCountry?: string
  addressLocality?: string
  addressRegion?: string | null
  postalCode?: string
  streetAddress?: string
  telephone?: string | null
  priceRange?: string
  url: string
  ratingValue: number
  reviewCount: number
  latitude?: number
  longitude?: number
  reviews?: ReviewType[]
  openingHours?: WithOpeningHoursFragment["openingHours"]
}

/**
 * Local business (LocalBusiness) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/local-business
 */

export const LocalBusinessStructuredData = ({
  name,
  description,
  image,
  addressCountry,
  addressLocality,
  addressRegion,
  postalCode,
  streetAddress,
  telephone,
  url,
  ratingValue,
  reviewCount,
  reviews,
  priceRange,
  openingHours,
  latitude,
  longitude,
}: LocalBusinessStructuredDataProps) => {
  const { i18n } = useTranslation()

  return (
    <JsonLd<LocalBusiness>
      item={{
        "@context": "https://schema.org",
        "@type": "SelfStorage",
        "@id": url,
        name,
        description,
        image: imageToMarkupRatios(image),
        url,
        priceRange,
        telephone: telephone ?? undefined,
        aggregateRating: getAggregateRatingSpecification({
          count: reviewCount,
          value: ratingValue,
        }),
        review: getReviewsSpecification({
          reviews,
          language: i18n.language,
        }),
        address: {
          "@type": "PostalAddress",
          addressCountry,
          addressLocality,
          addressRegion: addressRegion ?? undefined,
          postalCode,
          streetAddress,
        },
        geo: getGeoCoordinatesSpecification({ latitude, longitude }),
        openingHoursSpecification: getOpeningHoursSpecification(openingHours),
        parentOrganization: BOUNCE_ORGANIZATION,
      }}
    />
  )
}

type BreadcrumbListStructuredDataProps = {
  breadcrumbs: Breadcrumb[]
}
/**
 * Breadcrumb (BreadcrumbList) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/breadcrumb
 */
export const BreadcrumbListStructuredData = ({ breadcrumbs }: BreadcrumbListStructuredDataProps) => {
  const { getUrl } = useUrl()

  return (
    <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbs.map((item, index) => ({
          "@type": "ListItem",
          position: index + 1,
          name: item.name,
          item: getUrl({ path: item.href ?? "/", absolute: true }),
        })),
      }}
    />
  )
}

type ArticleStructuredDataProps = {
  headline: string
  datePublished: string
  dateModified?: Nullable<string>
  image: string | ImageField
  url: string
  author?: Organization | Person
}
/**
 * Article (Article, NewsArticle, BlogPosting) structured data
 * @see https://developers.google.com/search/docs/appearance/structured-data/article
 */
export const ArticleStructuredData = ({
  headline,
  datePublished,
  dateModified,
  image,
  url,
  author = BOUNCE_ORGANIZATION,
}: ArticleStructuredDataProps) => {
  return (
    <JsonLd<Article>
      item={{
        "@context": "https://schema.org",
        "@type": "Article",
        headline,
        datePublished,
        dateModified: dateModified ?? undefined,
        image: imageToMarkupRatios(image),
        publisher: BOUNCE_ORGANIZATION,
        author,
        mainEntityOfPage: url,
      }}
    />
  )
}

export const websiteStructuredDataScriptProps = () =>
  jsonLdScriptProps<WebSite>({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": `https://${config.domain}`,
    name: "Bounce Luggage Storage",
    alternateName: ["Bounce"],
    url: `https://${config.domain}/`,
  })

type GeoCoordinatesProps = {
  latitude?: number
  longitude?: number
}

const getGeoCoordinatesSpecification = ({ latitude, longitude }: GeoCoordinatesProps): GeoCoordinates | undefined =>
  latitude && longitude
    ? {
        "@type": "GeoCoordinates",
        latitude,
        longitude,
      }
    : undefined
