import { getMarketingParagraphClasses, getParagraphClasses } from "./style"
import type { ParagraphProps } from "./types"
import { useIsAppTheme } from "../../../contexts/Theme/Theme"

export const Paragraph = ({
  size = 1,
  as: As = "p",
  children,
  className,
  testId,
  style: _style,
  numberOfLines,
  ...rest
}: ParagraphProps) => {
  const isAppTheme = useIsAppTheme()

  const classes = isAppTheme
    ? getParagraphClasses({ size, numberOfLines, className })
    : getMarketingParagraphClasses({ size, numberOfLines, className })

  return (
    <As data-testid={testId ?? `paragraph-${size}`} className={classes} {...rest}>
      {children}
    </As>
  )
}
