import clsx from "clsx"

import type { ParagraphProps, ParagraphSize } from "./types"
import { getLineClampClass } from "../style"

type ParagraphClassArgs = Pick<ParagraphProps, "className" | "numberOfLines"> & {
  size: NonNullable<ParagraphSize>
}

export const getParagraphClasses = ({ size, className, numberOfLines }: ParagraphClassArgs): string => {
  return clsx(
    // Text sizes
    size === 1 && "text-[1rem]",
    size === 2 && "text-[0.875rem]",
    "font-sans font-normal leading-normal",
    getLineClampClass(numberOfLines),
    // Extra classes
    className,
  )
}

export const getMarketingParagraphClasses = ({ size, className, numberOfLines }: ParagraphClassArgs): string => {
  return clsx(
    "font-sans font-normal leading-[1.25] lg:leading-[1.3]",
    // Text sizes
    size === 1 && "text-[1.125rem] lg:text-[1.375rem]",
    size === 2 && "text-[0.875rem] lg:text-[1.125rem]",
    getLineClampClass(numberOfLines),
    // Extra classes
    className,
  )
}
